import React from 'react'

const L4J = () => {
  return (
    <div className="flex min-h-[calc(100vh-56px)] flex-col items-center px-5 md:px-0">
      <div className="mt-10 flex w-full max-w-2xl flex-col p-2.5">
        <span className="text-2xl font-semibold text-white">L4J</span>
        <span className="mt-5 text-lg text-[#FFFFFF75]">This demo of Edgio showcases the following:</span>
      </div>
    </div>
  )
}

export default L4J
